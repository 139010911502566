body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Courier New", monospace;
  color: #0077b6;
  background-color: #caf0f8;
  /* f5f3f4 */
  font-size: 24px;
  font-style: bold;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bannerImage {
  width: 100%;
  height: auto;
}

.portfolioTitle {
  font-family: "Courier";
  font-size: 35px;
  color: #0077b6;
  /* a4161a */
  padding: 2vmin;
  border: solid;
  margin: 5vmin;
}

.headingTitle {
  border-bottom: solid;
  padding: 2vmin;
  padding-bottom: 1vmin;
  padding-top: 0;
}

/* About Me --------------------------- */

.aboutMeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5vmin;
  margin-right: 5vmin;
  padding: 2vmin;
}

.aboutMeContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}
.profilePic {
  margin-top: 2vh;
  border-radius: 50%;
  height: 250x;
  width: 250px;
}

/* Projects ---------------------------*/

.projectsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vmin;
}

.projectImageContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: space-around;
  justify-content: center;
  margin: auto;
}

.individualProjectContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
}

.projectsTitle {
  position: center;
  margin-bottom: 0px;
}

.projectContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.individualProjectTitle {
  margin: 5vmin;
}

.projectImage {
  width: 350px;
  height: 252.36px;
  border-color: white;
  /* border: 5px solid #fff; */
  border-radius: 5%;
  margin: 10px;
}

.projectImage:hover {
  border-style: solid;
  border-color: #caf0f8; 
  border-width: 0.5vmin;
}

.projectDescription {
  margin: 50px;
  margin-bottom: 30px;
  max-width: 100vmin;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  color: white;
  text-decoration: none;
}

.modalContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #232021;
  margin: auto;
  margin-top: 10%;
  border-radius: 10px;
  max-width: 110vmin;
  overflow: scroll;
  height: 74vmin;
}

.modalButton:hover {
  cursor: pointer;
}

/* Contacts --------------------------- */

.footerContainer{
  margin-top: auto;
  display: flex; 
  flex-direction: column; 
  justify-content: center;
  text-align: center;
}

.social {
  margin-top: 5vh;;
}

.socialIcon {
  margin: 2vmin 5vmin 0 5vmin; 
  size: 30px;
}

.emailText {
  margin-bottom: 0;
}
